import React from 'react'
import { graphql } from 'gatsby'
import Img from 'gatsby-image'
import Layout from '../components/layout'
import SEO from '../components/seo'

const MyStory = ({ data }) => (
  <Layout>
    <SEO title="Fitness and Spirit- My Story" />
    <div className="container-main">
      <div className="container-main_div">
        <Img
          fluid={data.banner.childImageSharp.fluid}
          alt=""
          className="mb-10"
        />

        <p>
          Welcome to Fitness & Spirit, I am Celina Acosta, your Medical Exercise
          Specialist residing in Katy, TX. You are most likely here because you
          are seeking answers and healing for a healthier and more functional
          lifestyle. My personal life journey has brought me through my own
          health obstacles sharing the same desires as you. Allow me to share
          with you my story, knowing that healing and a functional life with
          ease awaits you!
        </p>
        <div className="container py-2 mx-10">
          <div className="flex flex-wrap -m-1 md:-m-2 space-around">
            <div className="flex flex-wrap w-1/3 ">
              <div className="w-8/12 p-1 md:p-2">
                <Img
                  fluid={data.one.childImageSharp.fluid}
                  alt=""
                  className="my-5"
                />
              </div>
            </div>
            <div className="flex flex-wrap w-1/3">
              <div className="w-8/12 p-1 md:p-2">
                <Img
                  fluid={data.two.childImageSharp.fluid}
                  alt=""
                  className="my-5"
                />
              </div>
            </div>
            <div className="flex flex-wrap w-1/3">
              <div className="w-8/12 p-1 md:p-2">
                <Img
                  fluid={data.three.childImageSharp.fluid}
                  alt=""
                  className="my-5"
                />
              </div>
            </div>
          </div>
        </div>
        <p>
          As a young girl, I was always seeking more, and to overcome my trauma,
          I put my God-given talents to use. Playing sports was an escape that
          gave me community, purpose and strength. I played a variety of sports
          and hit the gym for strength training. I discovered a new inner and
          outer beauty in my strength at the gym, resulting in fitness becoming
          my lifestyle.
        </p>
        <p>
          After graduating high school, I committed to endurance running. I
          found a new peace and passion along my runs. I ran a ton of 5K’s,
          several half-marathons, and completed one full marathon. Throughout my
          years of running, I had stopped strength training to focus solely on
          running, and I learned the hard way, the necessity of both! I endured
          ankle and knee injuries, and lived with chronic pain along with other
          health conditions.
        </p>
        <p>
          I persevered through my pain, understanding my journey and the
          necessity to keep going. I recommitted to strength training and
          modified my runs as necessary. After having children, I was diagnosed
          with Fibromyalgia and was faced with new health conditions which
          propelled me even deeper into my journey for pain relief and answers.
        </p>
        <p>
          I have learned that “you” are your best advocate and that perseverance
          can bring clarity. You are not alone and it is part of my mission to
          be present for you! In the midst of my suffering I discovered I had
          purpose and that I am a warrior! I am stronger, wiser, more
          experienced, and an overcomer. You can be too! “Are you ready?”
        </p>
        <p>
          My education and passions have also been very much of my journey
          shaping and equipping me with my skill-set. My education in Psychology
          and Philosophy with a love in Neuroscience, took a detour into
          education. Within my years of education, my wisdom came full circle
          when I started teaching Action Based Learning. This gave me hope and a
          redirection back into the fitness industry. “Exercise grows brain
          cells!” ~ Jean Blaydes Madigon
        </p>
        <h2>Experience/Certifications</h2>
        <ul>
          <li>Fitness & Fibromyalgia Specialist</li>
          <li>Medical Exercise Specialist</li>
          <li>Personal Trainer</li>
          <li>Lifestyle Fitness Coach</li>
          <li>Group Fitness Instructor</li>
          <li>Bootcamp Instructor</li>
          <li>Silver Sneakers</li>
          <li>Sports Nutrition</li>
          <li>Fitness & Fibromyalgia Specialist</li>
          <li>Medical Exercise Specialist</li>
        </ul>
      </div>
    </div>
  </Layout>
)

export default MyStory

export const query = graphql`
  query {
    banner: file(relativePath: { eq: "myStoryBanner.png" }) {
      childImageSharp {
        fluid(maxHeight: 220, maxWidth: 1204) {
          ...GatsbyImageSharpFluid
        }
      }
    }

    one: file(relativePath: { eq: "myStoryPic1.jpeg" }) {
      childImageSharp {
        fluid(maxHeight: 200, maxWidth: 300) {
          ...GatsbyImageSharpFluid
        }
      }
    }

    two: file(relativePath: { eq: "myStoryPic2.jpeg" }) {
      childImageSharp {
        fluid(maxHeight: 200, maxWidth: 300) {
          ...GatsbyImageSharpFluid
        }
      }
    }

    three: file(relativePath: { eq: "myStoryPic3.jpeg" }) {
      childImageSharp {
        fluid(maxHeight: 200, maxWidth: 300) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
